// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-events-jsx": () => import("./../../../src/pages/events.jsx" /* webpackChunkName: "component---src-pages-events-jsx" */),
  "component---src-pages-fellowship-program-jsx": () => import("./../../../src/pages/fellowship-program.jsx" /* webpackChunkName: "component---src-pages-fellowship-program-jsx" */),
  "component---src-pages-global-education-jsx": () => import("./../../../src/pages/global-education.jsx" /* webpackChunkName: "component---src-pages-global-education-jsx" */),
  "component---src-pages-imprint-jsx": () => import("./../../../src/pages/imprint.jsx" /* webpackChunkName: "component---src-pages-imprint-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-index-list-jsx": () => import("./../../../src/pages/index-list.jsx" /* webpackChunkName: "component---src-pages-index-list-jsx" */),
  "component---src-pages-research-practice-jsx": () => import("./../../../src/pages/research-practice.jsx" /* webpackChunkName: "component---src-pages-research-practice-jsx" */),
  "component---src-pages-residency-in-situated-philosophy-jsx": () => import("./../../../src/pages/residency-in-situated-philosophy.jsx" /* webpackChunkName: "component---src-pages-residency-in-situated-philosophy-jsx" */),
  "component---src-pages-speaker-series-jsx": () => import("./../../../src/pages/speaker-series.jsx" /* webpackChunkName: "component---src-pages-speaker-series-jsx" */),
  "component---src-pages-studio-for-germinating-technologies-jsx": () => import("./../../../src/pages/studio-for-germinating-technologies.jsx" /* webpackChunkName: "component---src-pages-studio-for-germinating-technologies-jsx" */),
  "component---src-pages-techniques-journal-jsx": () => import("./../../../src/pages/techniques-journal.jsx" /* webpackChunkName: "component---src-pages-techniques-journal-jsx" */),
  "component---src-pages-techniques-jsx": () => import("./../../../src/pages/techniques.jsx" /* webpackChunkName: "component---src-pages-techniques-jsx" */),
  "component---src-pages-visiting-researchers-jsx": () => import("./../../../src/pages/visiting-researchers.jsx" /* webpackChunkName: "component---src-pages-visiting-researchers-jsx" */),
  "component---src-templates-event-event-jsx": () => import("./../../../src/templates/event/event.jsx" /* webpackChunkName: "component---src-templates-event-event-jsx" */),
  "component---src-templates-project-project-jsx": () => import("./../../../src/templates/project/project.jsx" /* webpackChunkName: "component---src-templates-project-project-jsx" */),
  "component---src-templates-research-research-jsx": () => import("./../../../src/templates/research/research.jsx" /* webpackChunkName: "component---src-templates-research-research-jsx" */)
}

